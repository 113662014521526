import { styled } from '@mui/material/styles';
import { getZoom } from '../../utils/sizeUtils';

export const StyledFeedbackButton = styled('div')(({ theme }) => {
  const zoom = getZoom();

  return {
    '.feedback-button-open': {
        display: 'flex',
        justifyContend: 'end',
        position: 'fixed',
        margin: 'auto',
        right: '379px',
        bottom: '72px',
        transform: 'rotate(270deg)',
        zIndex: '9999 !important',
      
    },
    '.feedback-button-closed': {
        display: 'flex',
        justifyContend: 'end',
        position: 'fixed',
        margin: 'auto',
        right: '-33px',
        bottom: '72px',
        transform: 'rotate(270deg)',
        zIndex: '9999 !important',

      },
    zoom: `${zoom}%`,

  };
});

export const StyledFeedbackForm = styled('div')(({ theme }) => {
  const zoom = getZoom();
  return {
    transition: "all 200ms,opacity 400m",
    borderRadius: "8px 0px 0px 8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    zoom: `${zoom}%`,
    padding: "16px 24px 24px 24px",
    flexDirection: 'column',
    position: 'fixed',
    margin: 'auto',
    right: '0px',
    width: '410px',
    height: '100%',
    zIndex: "100",
    background: 'white',
    ".feedback-title":{
      color: "#000000",
      fontWeight: "bold",
    },
    ".feedback-close":{
      cursor: "pointer",
      color: "black",
    },
    ".feedback-text": {
      fontSize: "14px"
    },
    ".feedback-comment": {
      zIndex: "9999 !important",
      height: "235px"
    },
    ".feedback-submit": {
      width: "350px",
      display: "block",
      position: "fixed",
      bottom: "24px",
    },
    ".feedback-submited": {
      height:"718px"
    },
    ".feedback-submitted-icon":{
      color: "#000000",
    },
    ".required-question::after": {
      content: '" *"',
      color: "red",
    }
  };
});
