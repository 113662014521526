import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import EmwTypography from '../../../../../../../components/EmwTypography/EmwTypography';
import { StylesOrganizationListHead } from '../../OrganizationList/OrganizationCardStyledComponent';
import EmwIcon from '../../../../../../../components/EmwIcon/EmwIcon';
import { QtmIcon } from '@qtm/react';
import EngCategory from './EngCategory';
import { OrganizationCardContext } from '../../../context/OrganizationCardContextProvider';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import {
  highlightedTgiSelector,
  orgDetailsSelector,
  tourStatusSelector,
} from '../../../../../redux/selectors';
import { StyledEngCategoryPanel } from './styled';
import EmsList from '../../Ems/EmsList';
import { VIEW_MODE } from '../EngCategories';
import Section1FormItem from '../../OrganizationCardModal/components/Section1/Section1FormItem';
import PlusIcon from '../../../../../../../../lib/icons/PlusIcon';
import TourElement from '../../../../../../../components/Tour/TourElement/TourElement';

const TOUR_IDS = {
  EM: 'tour-orgCard-ENGINEERING_MANAGER_SECTION_ID',
  OPERATIONS: 'tour-orgCard-HEAD_OF_ENGINEERING_OPERATIONS_SECTION_ID',
  REFERENTS: 'tour-orgCard-REFEREES_SECTION_ID',
};

export default function EngCategoryPanel({
  engCategory,
  viewMode,
  targetField,
  section,
  categories,
}) {
  const context = useContext(OrganizationCardContext);
  const highlightedTgi = useSelector(highlightedTgiSelector);
  const shouldBeOpen = context.hasHighlightedItem(engCategory.childCategories, highlightedTgi);
  const [isOpen, setIsOpen] = useState(shouldBeOpen);
  const tour = useSelector(tourStatusSelector);
  const { list } = useSelector(orgDetailsSelector);
  const className = cls('organization-card-section');
  const [children, setChildren] = useState([]);
  const [newChildren, setNewChildren] = useState([]);
  const toggleClassName = cls({
    'pr-m': viewMode === VIEW_MODE.EDIT,
  });

  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  useEffect(() => {
    if (!tour && !context.hasHighlightedItem(engCategory, highlightedTgi)) {
      setIsOpen(false);
    }

    return () => {
      setIsOpen(false);
    };
  }, [list]);

  useEffect(() => {
    if (!isOpen && viewMode === VIEW_MODE.EDIT) {
      if (context.shouldBeOpen(engCategory, targetField.key)) {
        setIsOpen(context.shouldBeOpen(engCategory, targetField.key));
      } else {
        if (['EM'].includes(engCategory.type)) {
          setIsOpen(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (viewMode === VIEW_MODE.EDIT) {
      const _children = [];
      engCategory.engineers.map((engineer, index) => {
        _children.push(
          <div className="child">
            <TourElement id="tour-orgCard-Section1DropdownContent">
              <Section1FormItem
                key={engineer.id + '_' + index}
                item={engineer}
                type={engCategory.type}
                isNew={false}
                propKey={engCategory.type}
                index={index}
                targetKey={targetField.key}
                section={section}
                title={engineer.labelInfo}
                categories={categories}
              />
            </TourElement>
          </div>
        );
      });

      setChildren(_children);
    }
  }, [engCategory]);

  const renderChildren = useMemo(() => {
    if (engCategory.childCategories.length) {
      return engCategory.childCategories
        .filter(childCategory => childCategory[viewMode].visible)
        .map((childCategory, index) => {
          if (viewMode === VIEW_MODE.VIEW) {
            return <EngCategory key={index} engCategory={childCategory} viewMode={viewMode} />;
          }

          return (
            <div className="child">
              <EngCategory
                key={index}
                engCategory={childCategory}
                viewMode={viewMode}
                targetField={targetField}
                section={section}
              />
            </div>
          );
        });
    }

    if (viewMode === VIEW_MODE.EDIT) {
      return children;
    }

    const visibleEngineers = engCategory.engineers.filter(engineer => engineer.visible);
    return <EmsList list={visibleEngineers} isParent={true} type={engCategory.type} />;
  }, [engCategory, children]);

  const addItem = () => {
    const engineer = context.getEmptyItemFor(section, engCategory.type);
    const _newChildren = [...newChildren];
    const _children = [...children];
    const newChildIndex = children.length + 1 + newChildren.length + 1;

    setChildren(currentChildren => {
      return [
        ...currentChildren,
        <div className="child">
          <Section1FormItem
            key={engineer.id + '_' + currentChildren.length + 1}
            item={engineer}
            type={engCategory.type}
            isNew={true}
            propKey={engCategory.type}
            index={currentChildren.length + 1}
            targetKey={targetField.key}
            section={section}
            title={'title'}
            categories={categories}
          />
        </div>,
      ];
    });
  };

  const renderAddButton = () =>
    useMemo(() => {
      if (viewMode === VIEW_MODE.EDIT && engCategory.editMode.header.btnAdd.visible) {
        let disabled = !engCategory.editMode.header.btnAdd.enabled;
        return (
          <IconButton
            title="Add new"
            sx={{ padding: 0 }}
            disableRipple
            onClick={addItem}
            disabled={disabled}
            id={`btnAdd${engCategory.type}`}
          >
            <PlusIcon />
          </IconButton>
        );
      }

      return null;
    }, [engCategory, children]);

  const getTourId = () => {
    try {
      if (viewMode === VIEW_MODE.EDIT) {
        return TOUR_IDS[engCategory.type];
      }

      return '';
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <TourElement id={getTourId()}>
      <StyledEngCategoryPanel
        className={className}
        container
        engCategory={engCategory}
        viewMode={viewMode}
      >
        <StylesOrganizationListHead container className="organization-card-section-head">
          <Grid item mx={1}>
            <EmwIcon
              icon={engCategory[viewMode].header.icon}
              variant="outlined"
              classes="text-bluegrey-600"
            />
          </Grid>
          <EmwTypography
            classes="text-bluegrey-600"
            fontSize={`${viewMode === VIEW_MODE.VIEW ? 8 : 9}`}
            title={engCategory[viewMode].header.title}
          >
            {engCategory[viewMode].header.label}
          </EmwTypography>
          <Grid item ml={'auto'}>
            <Grid container className={toggleClassName}>
              {viewMode === VIEW_MODE.EDIT && renderAddButton()}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                id={`btnToggle${engCategory.type}`}
                onClick={() => setIsOpen(!isOpen)}
                sx={{ marginLeft: 'auto' }}
              >
                {isOpen ? (
                  <QtmIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <QtmIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </StylesOrganizationListHead>
        <Collapse in={isOpen} sx={{ width: '100%' }} className="collapse">
          {renderChildren}
        </Collapse>
      </StyledEngCategoryPanel>
    </TourElement>
  );
}
