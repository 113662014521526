import React from 'react';
import { VIEW_MODE } from '../EngCategories';
import EmsList from '../../Ems/EmsList';
import EngineersList from './EngeersList';

export default function EngCategoryList({
  engineers,
  viewMode,
  isParent,
  engineersOnEachLine,
  engCategory,
}) {
  if (viewMode === VIEW_MODE.VIEW) {
    if (!engCategory.viewMode.engineersOnEachLine) {
      return <EngineersList engineers={engineers} engCategory={engCategory} />;
    }
    return <EmsList list={engineers} isParent={isParent} />;
  }

  return viewMode;
}
