import { buildStep } from '../tour';
import {
  RIGHT_START,
  BOTTOM_START,
  LEFT_START,
  BTN_EXPORT_ORG_ID,
  BTN_GUESTS_ID,
  BTN_CLOSE_MODAL,
  BTN_CLOSE_MODAL_ID,
  BTN_EXPORT_CLOSE,
  TITLE_EXPORT_OPTIONS,
  BTN_EXPAND_DELIVERIES,
  BTN_EXPAND_DELIVERY_0,
  BTN_EXPAND_WP,
  BTN_EXPAND_WPM_0,
  BTN_EXPAND_DISCIPLINES,
  BTN_EDIT_DELIVERY,
  BTN_EXPAND_SOLUTIONS,
  BTN_EXPAND_DELIVERY_SOLUTION,
  BTN_ALLOCATE_CC,
  BTN_ADD_NEW_CC,
  BTN_CANCEL_EEI,
} from '../constants';

export const buildOrganizationEditBtnSteps = (
  getEngineersCategorySection,
  getIdFor,
  dinamycStep,
  tour,
  hasDisciplines,
  tourStepback,
  list,
  orgType,
  hasDeliveries,
  hasEmsEditButton,
  canAddDelivery,
  canEditDelivery,
  hasCers,
  canAddDiscipline,
  canEditDiscipline,
  hasCCTypes,
  hasHc,
  hasHcDeclared,
  hasEngDomains,
  hasDates,
  hasBtnReset,
  hasBtnSave,
  hasSolutions,
  showExcoms,
  showGuests,
  showPowerGuests,
  hasBtnAddNewCC,
  excoms,
  guests,
  powerGuests,
  btnEditGuests,
  hasBtnAllocateCC,
  hasBtnGuests,
  hasBtnExport,
  btnEEI,
  hzaIframeLoaded,
  hasEdmManager,
  hasWpmManager
) => {
  dinamycStep.push(
    buildStep(
      tour,
      async () => {
        await document.querySelector(BTN_CLOSE_MODAL).click();
        if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
          await document.querySelector(BTN_CLOSE_MODAL_ID).click();
        }
      },
      {
        id: 'OrganizationCardModalTitleStep',
        title: 'Edit Mode Bar',
        text: `This is the menu bar, where you can edit the name,
    declare headcount, select CC type, industry type, edit start/end date, reset settings, close, or save.
    Click next to continue.`,
        element: '#tour-orgCard-modalTile',
        on: BOTTOM_START,
        identifierClass: 'EditModeBarIdentifier',
        scroll: true,
      },
      null,
      null,
      400,
      null,
      async () => {
        if (document.querySelector(BTN_CLOSE_MODAL)) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      }
    )
  );

  dinamycStep.push(
    buildStep(
      tour,
      null,
      {
        id: 'OrganizationCardModalNameStep',
        title: 'Name Edit Area',
        text: `Here you can edit the name of the selected organization card.`,
        element: '#tour-orgCard-modalName',
        on: BOTTOM_START,
        identifierClass: 'tour-orgCard-modalNameClass',
        scroll: true,
      },
      null,
      null,
      0,
      null,
      async () => {
        if (document.querySelector(BTN_CLOSE_MODAL)) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      }
    )
  );
  if (hasHc || hasHcDeclared) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headcountEditSection',
          title: 'Head Count',
          text: `Allows you to declare the headcount for the selected organization`,
          element: '#tour-orgCard-headcount',
          on: BOTTOM_START,
          scroll: true,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }

  if (hasCCTypes) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ccTypesEditSection',
          title: 'CC Types',
          text: `Here you can select the type of the CC that suits the selected organization.`,
          element: '#organizationCard-cc-types',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  if (hasEngDomains) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'engDomainsEditSection',
          title: 'Engineering Domanins',
          text: `There you can select the engineering domains that suit the selected organization.`,
          element: '#tour-orgCard-engDomains',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  if (hasDates) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'datesEditSection',
          title: 'Dates',
          text: `Changing the dates will allow you to set a start and end date for the selected organization.`,
          element: '#tour-orgCard-dates',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  if (hasBtnReset) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'resetEditSection',
          title: 'Reset button',
          text: `This will reset all changes made.`,
          element: '#btnReset',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  dinamycStep.push(
    buildStep(
      tour,
      null,
      {
        id: 'closeEditSection',
        title: 'Close button',
        text: `This will close the current card. `,
        element: BTN_CLOSE_MODAL,
        on: BOTTOM_START,
      },
      null,
      null,
      0,
      null,
      async () => {
        if (document.querySelector(BTN_CLOSE_MODAL)) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      }
    )
  );
  if (hasBtnSave) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'saveEditSection',
          title: 'Save Changes',
          text: `Don’t forget to save your changes. Click next to continue.`,
          element: '#btnSave',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  if (orgType === 'CC') {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'countriesEditSection',
          title: 'Countries',
          text: `This is the countries category, where you need to select at least one country where the CC Belongs to. Multiple options can be selected.
        Click next to continue.`,
          element: '#tour-orgCard-countrySelectorId',
          on: RIGHT_START,
          identifierClass: 'tour-orgCard-countrySelectorId',
          scroll: true,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }
  dinamycStep.push(
    buildStep(
      tour,
      null,
      {
        id: 'engineeringManagerSection',
        title: 'Engineering Manager',
        text: `This is the Engineering Manager category, where we can add
        a person (or more) in the role of EM. We can add or change
        the person in this role by pressing the Edit (pencil symbol)
        button at the right side. Click next to continue.`,
        element: '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID',
        on: RIGHT_START,
        scroll: true,
      },
      null,
      null,
      0,
      null,
      async () => {
        if (document.querySelector(BTN_CLOSE_MODAL)) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      }
    )
  );

  if (hasEmsEditButton) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'editingPositionOrPersonSection',
          title: 'Editing a position/person',
          text: `After previously clicking the edit in the Engineering manager, we are now presented with the editable version of the same card. Here we can select a person using the name input, select type, dates, and click Update when finished. By clicking Cancel, no changes will be saved.
            Click Next to continue.`,
          element:
            '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID #tour-orgCard-Section1DropdownContent',
          on: RIGHT_START,
          scroll: true,
        },
        () => {
          if (
            getEngineersCategorySection('section1', 'EM').engineers[0].btnEdit.enabled &&
            getEngineersCategorySection('section1', 'EM').engineers[0].btnEdit.visible
          ) {
            if (
              document.querySelector('#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID .edit-button')
            ) {
              document
                .querySelector('#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID .edit-button')
                .click();
            }
          }
          return;
        },
        () => {
          if (
            getEngineersCategorySection('section1', 'EM').engineers[0].btnEdit.enabled &&
            getEngineersCategorySection('section1', 'EM').engineers[0].btnEdit.visible
          ) {
            if (
              document.querySelector(
                '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID #btnFormItemCancel-EM-0'
              )
            ) {
              document
                .querySelector(
                  '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID #btnFormItemCancel-EM-0'
                )
                .click();
            }
          }
          return;
        },
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
  }

  dinamycStep.push(
    buildStep(
      tour,
      null,
      {
        id: 'HOEOSection',
        title: 'Operations',
        text: `This is the Operations category, where we can add a person (or more) in the roles of HoEO and KPI Steward.\n
    Click next to continue.`,
        element: '#tour-orgCard-HEAD_OF_ENGINEERING_OPERATIONS_SECTION_ID',
        on: RIGHT_START,
        scroll: true,
      },
      null,
      null,
      0,
      null,
      async () => {
        if (document.querySelector(BTN_CLOSE_MODAL)) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      }
    )
  );
  if (hasCers) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'cerSection',
          title: 'Country Engineering Representative',
          text: `This is the Country Engineering Representative category, where we can add 
          a person (or more) in the role of CER. We can add or change the person in this role by pressing the Edit (pencil symbol)button at the right side. Click next to continue.`,
          element: '#tour-orgCard-CER',
          on: RIGHT_START,
          scroll: true,
        },
        async () => {
          setTimeout(() => {
            if (document.querySelector('#toggleCER')) {
              document.querySelector('#toggleCER').click();
            }
          }, 100);
        },
        null,
        500,
        null,
        null
      )
    );
  }

  dinamycStep.push(
    buildStep(
      tour,
      null,
      {
        id: 'referentsEditSection',
        title: 'Referents',
        text: `This is the Referents category, where we can manage the people in charge.
    Click next to continue.`,
        element: '#tour-orgCard-REFEREES_SECTION_ID',
        on: RIGHT_START,
        identifierClass: 'refereesIdentifier',
        scroll: true,
      },
      null,
      null,
      300,

      /* Expand all delivery items when click Next */

      async () => {
        setTimeout(() => {
          if (document.querySelector(BTN_EXPAND_DELIVERIES)) {
            document.querySelector(BTN_EXPAND_DELIVERIES).click();
          }
        }, 200);
        setTimeout(() => {
          if (document.querySelector(BTN_EXPAND_DELIVERY_0)) {
            document.querySelector(BTN_EXPAND_DELIVERY_0).click();
          }
        }, 200);
        setTimeout(() => {
          if (document.querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandSolutions')) {
            document
              .querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandSolutions')
              .click();
          }
        }, 300);
        setTimeout(() => {
          if (
            document.querySelector(
              '#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0-solution-0'
            )
          ) {
            document
              .querySelector(
                '#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0-solution-0'
              )
              .click();
          }
        }, 400);
        setTimeout(() => {
          if (document.querySelector(BTN_EXPAND_WP)) {
            document.querySelector(BTN_EXPAND_WP).click();
          }
        }, 300);
        setTimeout(() => {
          if (document.querySelector(BTN_EXPAND_WPM_0)) {
            document.querySelector(BTN_EXPAND_WPM_0).click();
          }
        }, 300);

        if (
          !hasDeliveries &&
          !(list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled)
        ) {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 100);
        }
      },
      async () => {
        await document.querySelector(BTN_CLOSE_MODAL).click();
        setTimeout(() => {
          if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
            document.querySelector(BTN_CLOSE_MODAL_ID).click();
          }
        }, 100);
      }
    )
  );
  if (hasDeliveries) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'deliveriesEditSection',
          title: 'Delivery Discipline',
          text: `This is the deliveries category, which includes all the categories below, deliveries, which include solutions, and projects. Each position can be edited and more can be added.`,
          element: '#tour-orgCard-Section2DeliveriesPanel',
          on: LEFT_START,
          identifierClass: 'deliveries',
          scroll: true,
        },
        null,
        null,
        100,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_DISCIPLINES)) {
              document.querySelector(BTN_EXPAND_DISCIPLINES).click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandEdms')) {
              document
                .querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandEdms')
                .click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandWpms')) {
              document
                .querySelector('#tour-orgCard-Section2DeliveriesPanel #btnExpandWpms')
                .click();
            }
          }, 100);
          if (
            !hasDeliveries &&
            !(list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled) &&
            !hasCers
          ) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
            setTimeout(() => {
              if (document.querySelector(BTN_GUESTS_ID)) {
                document.querySelector(BTN_GUESTS_ID).click();
              }
            }, 300);
          }
        },
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );

    if (canAddDelivery) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'newDeliveryBtn',
            title: 'New Delivery button',
            text: `This will create a new delivery.`,
            element: '#btnEditViewAddDeliveries',
            on: LEFT_START,
            scroll: true,
          },
          null,
          null,
          0,
          null,
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'firstDeliverySection',
          title: 'Delivery',
          text: `You can now click edit and fill in the necessary information.`,
          element: '#tour-orgCard-DeliveryFormItem-0',
          on: LEFT_START,
          scroll: true,
        },
        () => {
          if (document.querySelector('#btnFormItemCancel-null-0')) {
            document.querySelector('#btnFormItemCancel-null-0').click();
          }
        },
        null,
        0,
        async () => {
          if (
            !canEditDelivery &&
            !list.data.section1.btnAlocateCC.visible &&
            !list.data.section1.btnAlocateCC.enabled &&
            !hasDisciplines
          ) {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 200);
            }
          }
        },
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
    if (canEditDelivery) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'secondDeliverySection',
            title: 'Edit Delivery',
            text: `Once you filled in the necessary information, you can click on update and the card will be updated.`,
            element: '#tour-orgCard-DeliveryFormItem-0',
            on: LEFT_START,
            scroll: true,
          },
          async () => {
            setTimeout(() => {
              if (document.querySelector(BTN_EDIT_DELIVERY)) {
                document.querySelector(BTN_EDIT_DELIVERY).click();
              }
            }, 100);
          },
          async () => {},
          700,
          async () => {
            if (!hasDisciplines && document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          },
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
    if (hasEdmManager) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'edmSection',
            title: 'Engineering Delivery Manager',
            text: `This is the Engineering Delivery Manager section. Each position can be edited and more can be added.`,
            element: '#tour-orgCard-edm-0',
            on: LEFT_START,
            scroll: true,
          },
          async () => {
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_SOLUTIONS)) {
                document.querySelector(BTN_EXPAND_SOLUTIONS).click();
              }
            }, 100);

            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION)) {
                document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WP)) {
                document.querySelector(BTN_EXPAND_WP).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WPM_0)) {
                document.querySelector(BTN_EXPAND_WPM_0).click();
              }
            }, 100);
          },
          async () => {},
          500,
          async () => {
            if (!hasDisciplines && document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          },
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
    if (hasWpmManager) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'wpmSection',
            title: 'Work Package Manager',
            text: `This is the Work Package section. Each position can be edited and more can be added.`,
            element: '#tour-orgCard-wpm-0',
            on: LEFT_START,
            scroll: true,
          },
          async () => {
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_SOLUTIONS)) {
                document.querySelector(BTN_EXPAND_SOLUTIONS).click();
              }
            }, 100);

            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION)) {
                document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WP)) {
                document.querySelector(BTN_EXPAND_WP).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WPM_0)) {
                document.querySelector(BTN_EXPAND_WPM_0).click();
              }
            }, 100);
          },
          async () => {},
          500,
          async () => {
            if (!hasDisciplines && document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          },
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
    if (hasSolutions) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'solutionSection',
            title: 'Solutions/Projects',
            text: `This is the Solutions/Projects category witch contains Work Package section. Each position can be edited and more can be added.`,
            element: '#tour-orgCard-SolutionFormItem-0',
            on: LEFT_START,
            scroll: true,
          },
          async () => {
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_SOLUTIONS)) {
                document.querySelector(BTN_EXPAND_SOLUTIONS).click();
              }
            }, 100);

            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION)) {
                document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WP)) {
                document.querySelector(BTN_EXPAND_WP).click();
              }
            }, 100);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_WPM_0)) {
                document.querySelector(BTN_EXPAND_WPM_0).click();
              }
            }, 100);
          },
          async () => {},
          500,
          async () => {
            if (!hasDisciplines && document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          },
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
  }

  if (hasDisciplines) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_DELIVERIES)) {
              document.querySelector(BTN_EXPAND_DELIVERIES).click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_DELIVERY_0)) {
              document.querySelector(BTN_EXPAND_DELIVERY_0).click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_SOLUTIONS)) {
              document.querySelector(BTN_EXPAND_SOLUTIONS).click();
            }
          }, 100);

          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION)) {
              document.querySelector(BTN_EXPAND_DELIVERY_SOLUTION).click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_WP)) {
              document.querySelector(BTN_EXPAND_WP).click();
            }
          }, 100);
          setTimeout(() => {
            if (document.querySelector(BTN_EXPAND_WPM_0)) {
              document.querySelector(BTN_EXPAND_WPM_0).click();
            }
          }, 100);
        },
        {
          id: 'DisciplinesSection',
          title: 'Disciplines',
          text: `This is the disciplines category. Each position can be edited and more can be added. `,
          element: '#tour-orgCard-Section3DisciplinesPanel',
          on: LEFT_START,
          scroll: true,
          identifierClass: 'disciplines',
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );

    if (canAddDiscipline) {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'newDisciplineBtn',
            title: 'Add New Discipline button',
            text: `This will create a new discipline.`,
            element: '#btnAddNewDisciplines',
            on: LEFT_START,
            scroll: true,
          },
          null,
          null,
          0,
          null,
          async () => {
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            }
          }
        )
      );
    }
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'firstDisciplineSection',
          title: 'Discipline',
          text: `You can now click edit and fill in the necessary information. Discipline name can be manually added or you can select the discipline name based on Job Family.`,
          element: '#tour-orgCard-DisciplineFormItem-0',
          on: LEFT_START,
          scroll: true,
        },
        null,
        null,
        800,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        },
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 100);
          }
        }
      )
    );
    if (hasBtnAllocateCC) {
      dinamycStep.push(
        buildStep(
          tour,
          async () => {
            if (document.querySelector('#btnEdit')) {
              await document.querySelector('#btnEdit').click();
            }
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DISCIPLINES)) {
                document.querySelector(BTN_EXPAND_DISCIPLINES).click();
              }
            }, 500);
          },
          {
            id: 'btnAllocateCC',
            title: 'Allocate CC button',
            text: `Allocate CC (allocates CC to other organization).`,
            element: BTN_ALLOCATE_CC,
            on: RIGHT_START,
          },
          null,
          null,
          100,
          async () => {
            if (
              list.data.section1.btnAlocateCC.visible &&
              list.data.section1.btnAlocateCC.enabled
            ) {
              setTimeout(() => document.querySelector(BTN_ALLOCATE_CC).click(), 300);
            }
            if (document.querySelector(BTN_CLOSE_MODAL)) {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
              if (
                !list.data.section1.btnAlocateCC.visible &&
                !list.data.section1.btnAlocateCC.enabled
              ) {
                setTimeout(() => {
                  if (document.querySelector(BTN_GUESTS_ID)) {
                    document.querySelector(BTN_GUESTS_ID).click();
                  }
                }, 300);
              }
            }
          },
          async () => {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 500);
          }
        )
      );
    }
  }

  // if (hasCers) {
  //   dinamycStep.push(
  //     buildStep(
  //       tour,
  //       null,
  //       {
  //         id: "cerSection",
  //         title: "Country Engineering Representative",
  //         text: `This is the Country Engineering Representative category, where we can add
  //         a person (or more) in the role of CER. We can add or change the person in this role by pressing the Edit (pencil symbol)button at the right side. Click next to continue.`,
  //         element: "#tour-orgCard-CER",
  //         on: "right-start",
  //         scroll: true,
  //       },
  //       null,
  //       null,
  //       500,
  //       async () => {
  //         await document.querySelector("#btnCloseModal").click();
  //         setTimeout(() => {
  //           if (document.querySelector("#btnClose-modal")) {
  //             document.querySelector("#btnClose-modal").click();
  //           }
  //         }, 500);
  //       },
  //       async () => {
  //         await document.querySelector("#btnCloseModal").click();
  //         setTimeout(() => {
  //           if (document.querySelector("#btnClose-modal")) {
  //             document.querySelector("#btnClose-modal").click();
  //           }
  //         }, 500);
  //       }
  //     )
  //   );
  // }

  if (list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        },
        {
          id: 'ccAlocating',
          title: 'Allocating CC',
          text: `This will allocate the selected CC to another GBU or BL. Once you filled the correct information, click Save.`,
          element: '#tour-orgCard-EmwDialogID',
          on: RIGHT_START,
          identifierClass: 'allocateCCIdentifier',
          scroll: true,
        },
        null,
        null,
        500,
        async () => {},
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ccAlocatingSection',
          title: 'Name field',
          text: `Here is the field where you will have to enter the name. Click next to continue.`,
          element: '#allocateName',
          on: RIGHT_START,
          identifierClass: 'allocateName',
          scroll: true,
        },
        null,
        null,
        500,
        async () => {},
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ccAlocatingSection',
          title: 'GBU - BL',
          text: `Here you will select the GBU and BL where the CC will be allocated. Click next to continue.`,
          element: '#allocateGBU',
          on: RIGHT_START,
          identifierClass: 'allocateName',
          scroll: true,
        },
        null,
        null,
        500,
        async () => {},
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'ccAlocatingSection',
          title: 'Save Changes',
          text: `Don’t forget to save your changes. Click next to continue.`,
          element: BTN_CLOSE_MODAL_ID,
          on: RIGHT_START,
          identifierClass: 'allocateName',
          scroll: true,
        },
        null,
        null,
        500,
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        },
        async () => {
          if (document.querySelector('#btnClose')) {
            await document.querySelector('#btnClose').click();
          }
        }
      )
    );
  }

  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (list.data.section1.btnAlocateCC.visible && list.data.section1.btnAlocateCC.enabled) {
            setTimeout(() => document.querySelector(BTN_ALLOCATE_CC).click(), 300);
          }
          if (!hasBtnAllocateCC) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 100);
          }
          if (!hasBtnAllocateCC && hasDisciplines && canAddDiscipline) {
            if (document.querySelector('#btnEdit')) {
              await document.querySelector('#btnEdit').click();
            }
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DISCIPLINES)) {
                document.querySelector(BTN_EXPAND_DISCIPLINES).click();
              }
            }, 500);
          }
          if (!hasBtnAllocateCC && canEditDelivery && !hasDisciplines) {
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DELIVERIES)) {
                document.querySelector(BTN_EXPAND_DELIVERIES).click();
              }
            }, 200);
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DELIVERY_0)) {
                document.querySelector(BTN_EXPAND_DELIVERY_0).click();
              }
            }, 200);
            setTimeout(() => {
              if (document.querySelector(BTN_EDIT_DELIVERY)) {
                document.querySelector(BTN_EDIT_DELIVERY).click();
              }
            }, 200);
          }
        },

        {
          id: 'btnGuests',
          title: 'Manage Guest/Power Guest  button',
          text: `Opens View Guest/PowerGuest card where you can view Guests and Power Guests.`,
          element: BTN_GUESTS_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_GUESTS_ID)) {
              document.querySelector(BTN_GUESTS_ID).click();
            }
          }, 300);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }, 200);
        },
        {
          id: 'gpgContainer',
          title: 'View Guest/PowerGuest',
          text: `Guest/Power Guest (by pressing on Edit button, a modal will be open where you can manage Guest/Power Guest access).`,
          element: '.organization-card-container',
          on: RIGHT_START,
          scroll: true,
        },

        null,
        null,
        100,
        async () => {
          if (btnEditGuests) {
            setTimeout(() => {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }, 500);
          }
          setTimeout(() => {
            if (document.querySelector('#btnToggleExcom')) {
              document.querySelector('#btnToggleExcom').click();
            }
          }, 500);
          if (!btnEditGuests) {
            if (document.querySelector('#btnBack')) {
              document.querySelector('#btnBack').click();
            }
          }
        },
        async () => {}
      )
    );

    if (btnEditGuests) {
      if (showExcoms) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 100);
            },
            {
              id: 'tour-Excom',
              title: 'Excom Category',
              text: `This is the Excom category, where we can add 
              a person   (or more) in the role of Excom. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-excom',
              on: RIGHT_START,
              identifierClass: 'Excom',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showPowerGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            async () => {
              if (!showExcoms) {
                await document.querySelector(BTN_CLOSE_MODAL).click();
                setTimeout(() => {
                  if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                    document.querySelector(BTN_CLOSE_MODAL_ID).click();
                  }
                }, 200);
              }
            },

            {
              id: 'tour-PowerGuest',
              title: 'Power Guest Category',
              text: `This is the Power Guest  category, where we can add 
              a person  (or more) in the role of Power Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-powerguest',
              on: RIGHT_START,
              identifierClass: 'PowerGuest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {},
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 100);
            }
          )
        );
      }

      if (showGuests) {
        dinamycStep.push(
          buildStep(
            tour,
            null,
            {
              id: 'tour-Guest',
              title: 'Guest Category',
              text: `This is the Guest  category, where we can add 
              a person  (or more) in the role of Guest. We can add or change the person in this role by pressing the Edit (pencil symbol)
              button at the right side. Click next to continue.`,
              element: '#tour-orgCard-guest',
              on: RIGHT_START,
              identifierClass: 'Guest',
              scroll: true,
            },
            null,
            null,
            500,
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            },
            async () => {
              await document.querySelector(BTN_CLOSE_MODAL).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
                if (document.querySelector('#btnBack')) {
                  document.querySelector('#btnBack').click();
                }
              }, 200);
            }
          )
        );
      }
    }
  }
  if (hasBtnAddNewCC && (orgType === 'BL' || orgType === 'Domain')) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_GUESTS_ID)) {
              document.querySelector(BTN_GUESTS_ID).click();
            }
          }, 100);
          setTimeout(() => {
            if (btnEditGuests) {
              if (document.querySelector('#btnEdit')) {
                document.querySelector('#btnEdit').click();
              }
            }
          }, 200);
        },
        {
          id: 'btnAddNewOrganization',
          title: 'Add new organization button',
          text: `New organization (creates new organization).`,
          element: '#btnAddNewOrganization',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_ADD_NEW_CC)) {
              document.querySelector(BTN_ADD_NEW_CC).click();
            }
          }, 500);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        },
        {
          id: 'countriesAddNewCc',
          title: 'Countries',
          text: `This is the countries category, where you need to select at least one country where the CC Belongs to. Multiple options can be selected.
          Click next to continue.`,
          element: '#tour-orgCard-countrySelectorId',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        1000,
        async () => {},
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'emAddNewCc',
          title: 'Engineering Manager',
          text: `This is the Engineering Manager category, where we can add 
          a person (or more) in the role of Engineering Manager. We can add or change the person in this role by pressing the Edit (pencil symbol)
          button at the right side. Click next to continue.`,
          element: '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        500,
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
  }

  if (hasBtnExport) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (hasBtnGuests && !hasBtnAddNewCC) {
            setTimeout(() => {
              if (document.querySelector(BTN_GUESTS_ID)) {
                document.querySelector(BTN_GUESTS_ID).click();
              }
            }, 100);
            setTimeout(() => {
              if (btnEditGuests) {
                if (document.querySelector('#btnEdit')) {
                  document.querySelector('#btnEdit').click();
                }
              }
            }, 200);
          }
          if (hasBtnGuests && (orgType === 'GBU' || orgType === 'GROUP')) {
            setTimeout(() => {
              if (document.querySelector(BTN_GUESTS_ID)) {
                document.querySelector(BTN_GUESTS_ID).click();
              }
            }, 100);
            setTimeout(() => {
              if (btnEditGuests) {
                if (document.querySelector('#btnEdit')) {
                  document.querySelector('#btnEdit').click();
                }
              }
            }, 200);
          }

          if (!hasBtnAllocateCC && !hasBtnGuests) {
            if (document.querySelector('#btnEdit')) {
              await document.querySelector('#btnEdit').click();
            }
            setTimeout(() => {
              if (document.querySelector(BTN_EXPAND_DISCIPLINES)) {
                document.querySelector(BTN_EXPAND_DISCIPLINES).click();
              }
            }, 500);
          }
          if (hasBtnAddNewCC) {
            setTimeout(() => {
              if (document.querySelector(BTN_ADD_NEW_CC)) {
                document.querySelector(BTN_ADD_NEW_CC).click();
              }
            }, 300);
          }
        },
        {
          id: 'btnExport',
          title: 'Export button',
          text: `Allows you to export custom data.`,
          element: BTN_EXPORT_ORG_ID,
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_EXPORT_ORG_ID)) {
              document.querySelector(BTN_EXPORT_ORG_ID).click();
            }
          }, 100);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        {
          id: 'headerExport',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here we have multiple options to choose from that will be exported in the final file. Click next to continue`,
          element: '#tour-header-export',
          on: BOTTOM_START,
        },
        null,
        null,
        500,
        null,
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportList',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here we can select to export persons based on their roles. Whatever we select will be part of the exported file. Click next to continue.`,
          element: '#tour-export-list',
          on: RIGHT_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportBottom',
          title: TITLE_EXPORT_OPTIONS,
          text: `Disciplines and deliveries, including underlying categories, are available for export. Whatever we select will be part of the exported file. Click next to continue.`,
          element: '#tour-bottom-export',
          on: RIGHT_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );
    if (orgType === 'GROUP') {
      dinamycStep.push(
        buildStep(
          tour,
          null,
          {
            id: 'headerExportTG',
            title: TITLE_EXPORT_OPTIONS,
            text: `Selecting this option will include the thales organization card in the exported file. Click next to continue.`,
            element: '#tour-thales-group-export',
            on: BOTTOM_START,
          },
          null,
          null,
          0,
          null,
          async () => {
            if (document.querySelector(BTN_EXPORT_CLOSE)) {
              await document.querySelector(BTN_EXPORT_CLOSE).click();
              setTimeout(() => {
                if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                  document.querySelector(BTN_CLOSE_MODAL_ID).click();
                }
              }, 200);
            }
          }
        )
      );
    }
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportDropdowns',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here you have the option to select which GBU, BL, and CC to be part of the export. Multiple choices can be selected. Click next to continue.`,
          element: '#tour-dropdowns-export',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportFileName',
          title: TITLE_EXPORT_OPTIONS,
          text: `Here you can enter the name of the file that will be generated. Click next to continue.`,
          element: '#tour-file-name',
          on: BOTTOM_START,
        },
        null,
        null,
        0,
        null,
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headerExportButtons',
          title: TITLE_EXPORT_OPTIONS,
          text: `You can choose to close and exit, or click Export to download the file to your computer. `,
          element: '#export-buttons',
          on: BOTTOM_START,
        },
        null,
        null,
        300,
        async () => {
          await document.querySelector(BTN_EXPORT_CLOSE).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        async () => {
          if (document.querySelector(BTN_EXPORT_CLOSE)) {
            await document.querySelector(BTN_EXPORT_CLOSE).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        }
      )
    );
  }
  if (btnEEI && hzaIframeLoaded) {
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (hasBtnExport) {
            setTimeout(() => {
              if (document.querySelector(BTN_EXPORT_ORG_ID)) {
                document.querySelector(BTN_EXPORT_ORG_ID).click();
              }
            }, 200);
          }
        },
        {
          id: 'btnEEI',
          title: "Upload/Download Kpi's data button",
          text: `Allows you to export custom data.`,
          element: '#btn-KPI',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btn-KPI')) {
              document.querySelector('#btn-KPI').click();
            }
          }, 200);
        }
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_CANCEL_EEI)) {
              document.querySelector(BTN_CANCEL_EEI).click();
            }
          }, 200);
        },
        {
          id: 'tour-EEI',
          title: 'Upload/Download section',
          text: `Allows you to upload or download kpi files.`,
          element: '#tour-KPI',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        null
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'download-container',
          title: 'Upload/Download Excel',
          text: `Choose one of the 2 below and select your file, or download your file.`,
          element: '#tour-download',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        null
      )
    );
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'export-eei-actions',
          title: 'Cancel',
          text: `Close the modal.`,
          element: '#export-kpi-actions',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector(BTN_CANCEL_EEI)) {
              document.querySelector(BTN_CANCEL_EEI).click();
            }
          }, 100);
        }
      )
    );
  }
};
