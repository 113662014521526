import React, { useEffect, useState } from 'react';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableFiltersSelector,
  generalFiltersSelectors,
  selectedGeneralFilters,
} from '../../redux/selectors';
import { setSelectedFilters } from '../../redux/actions';
import { QtmCheckbox } from '@qtm/react';
import { SELECT_ALL_ID } from '../../hooks/useGeneralFilters';
import { selectAllOption2 } from '../GeneralFiltersCard/GeneralFiltersCard';

export default function GeneralFilterListItem({ type, item, style }) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectedGeneralFilters);
  const availableFilters = useSelector(availableFiltersSelector);
  const [name, setName] = useState();
  const generalFilters = useSelector(generalFiltersSelectors);

  useEffect(() => {
    if (item) {
      setName(item.region ? item.name : item.code);
      const _availableFilters = { ...availableFilters };
      const _selectedFilters = { ...selectedFilters };

      _availableFilters[type].map(filter => {
        const filterChecked = _selectedFilters[type].find(
          filterCheck => filterCheck.id === filter.id
        );

        if (filterChecked) {
          filter.checked = filterChecked.checked;
        }

        return filter;
      });
    }
  }, [item]);

  useEffect(() => {
    if (!selectedFilters[type].length) {
      availableFilters[type].map(filter => (filter.checked = false));
    }
  }, [selectedFilters]);

  const handleClick = event => {
    const _selectedFilters = { ...selectedFilters };
    const filterExists = _selectedFilters[type].find(filter => filter.id === item.id);
    const _availableFilters = { ...availableFilters };
    let isSelectAll;

    if (item.id === SELECT_ALL_ID) {
      isSelectAll = event.target.checked;
      selectAllOption2[type].checked = isSelectAll;
    }

    if (filterExists) {
      _selectedFilters[type] = _selectedFilters[type].filter(filter => filter.id !== item.id);
    } else {
      if (isSelectAll) {
        _selectedFilters[type] = _availableFilters[type].map(filter => {
          filter.checked = isSelectAll;
          return filter;
        });
      } else {
        _selectedFilters[type].push(item);
      }
    }

    _availableFilters[type].map(filter => {
      if (item.id === SELECT_ALL_ID) {
        filter.checked = isSelectAll;
      } else if (filter.id === item.id) {
        filter.checked = !item.checked;
      }

      return filter;
    });

    _selectedFilters[type] = _selectedFilters[type].filter(
      selectedFilter => selectedFilter.checked
    );

    dispatch(setSelectedFilters(_selectedFilters));
    // TODO: GIANINA - IF AN ORGANIZATION IS DESELECTED, DESELECT SELECT ALL OPTION;
    // if (_selectedFilters[type].length !== generalFilters[type].length) {
    //   const selectAllNode = document.getElementById(`${type}-Select All`);

    //   if (selectAllNode.checked) {
    //     selectAllNode.click();
    //   }
    // }

    // if (_selectedFilters[type].length === generalFilters[type].length) {
    //   const selectAllNode = document.getElementById(`${type}-Select All`);

    //   if (!selectAllNode.checked) {
    //     selectAllNode.click();
    //   }
    // }
  };

  return (
    <div style={style} title={name}>
      <label className="listItem flex ml-xxs overflow-hidden items-center">
        <QtmCheckbox
          inputId={`${type}-${item.code}`}
          id={`${type}-${item.code}`}
          checked={item && item.checked}
          size="small"
          onValueChanged={handleClick}
        />
        <EmwTypography fontSize={8}>{name}</EmwTypography>
      </label>
    </div>
  );
}
