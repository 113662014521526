import React, { useContext, useEffect, useState } from 'react';
import { EmwButton, EmwDropDownList } from '../../../../../../../lib/common';
import { TdsIcon, TdsTypography } from '@tds/react';
import { Grid, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import KPIActions from './KPIActions';
import { StyledKPIDialog, StyledHeaderTitle } from './styled';
import { computeURL, fetchOptions, fetchOptionsUpload } from '../../../../../../../util/fetch';
import { useDispatch, useSelector } from 'react-redux';
import {
  orgDetailsSelector,
  perspectiveSelector,
  schemaSelector,
} from '../../../../redux/selectors';
import KPISuccessModal from './KPISuccessModal';
import KPIErrorModal from './KPIErrorModal';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';
import { DATE_TIME_FORMAT, ORGANIZATION_PERSPECTIVE } from '../../../../constants';
import moment from 'moment';
import { read, utils } from 'xlsx';
import { hzaIframeSelector } from '../../../../../header/redux/selectors';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import EmwIcon from '../../../../../../components/EmwIcon/EmwIcon';
import {
  excelToJson,
  getWorksheetRows,
  jsonToExcel,
  UPLOAD_EXCEL_TYPE,
} from '../../../../../../utils/xcelUtils';
import FeedbackButton from '../../../../../feedback/FeedbackButton';
import { feedbackInsideModal } from '../../../../../feedback/redux/actions';
import { userActivity } from '../../../../../userActivity/redux/actions';
import { USER_ACTIVITY_SERVICES } from '../../../../../userActivity/constants';
import { v4 as uuidv4 } from 'uuid';

export default function ExportKPI({ data }) {
  const hzaContext = useContext(HzaContext);
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const perspective = useSelector(perspectiveSelector);
  const dispatch = useDispatch();
  const snackBar = useAppSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenC3, setIsOpenC3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKPI, setSelectedKPI] = useState('');
  const [selectedOption, setSelectedOption] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const [yearList, setYearList] = useState(null);
  const [defaultYear, setDefaultYear] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [openError, setOpenError] = useState(false);
  const schema = useSelector(schemaSelector);
  const orgDetails = useSelector(orgDetailsSelector);
  const [errorFileName, setErrorFileName] = useState('');
  const [responseText, setResponseText] = useState('');
  const [errorComments, setErrorComments] = useState([]);
  const [fileSource, setFileSource] = useState(false);
  const [wfbTimestamp, setWfbTimestamp] = useState('');
  const startYear = new Date().getFullYear() + 1;
  const currentYear = startYear - 1;
  const endYear = 2022;
  const years = Array.from({ length: startYear - endYear + 1 }, (_, index) => startYear - index);
  const isGroup = orgDetails.list.data.cardDetails.type.includes('GROUP', 'GROUP_COUNTRY');
  const isGroupCountry = orgDetails.list.data.cardDetails.type.includes('GROUP_COUNTRY');
  const isRegion = orgDetails.list.data.cardDetails.type === 'REGION';
  const isCountry = orgDetails.list.data.cardDetails.type === 'COUNTRY';
  const countryName = isCountry
    ? orgDetails.list.data.section1.name.value
    : schema.path.selectedCountry.code;
  const regionName = schema.path.selectedRegion.code;
  const isFromByCountry = perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY;
  const kpiOptions = [
    { value: 'EEI', text: 'EEI', info: 'Engineering Efficiency Index' },
    {
      value: 'WFB',
      text: 'WFB',
      info: 'Workforce Breakdown',
      disabled: isFromByCountry || isGroupCountry ? true : false,
    },
  ];
  const title = "Manage KPI's Data";
  const subtitle = 'Select a KPI and choose an action below';
  const subtitleGroup = 'Select a KPI to download';
  const hoverTitle = "Manage KPI's Data";
  const THALES_GROUP_C3 = 'THALES GROUP CONFIDENTIAL';
  const THALES_GROUP_C2 = 'THALES GROUP LIMITED DISTRIBUTION';
  const APPLICATION_JSON = 'application/json';
  const UPDATE_DATE = 'Update date';

  const getNodeType = (type, selectedType) => {
    if (type === 'GBU') {
      return schema.path.selectedGBU.code;
    }

    if (type === 'BL' && selectedType !== 'GBU') {
      return schema.path.selectedBL.code;
    }

    if (type === 'CC' && selectedType === 'CC') {
      return orgDetails.list.data.section1.name.value;
    }
    if (type === 'COUNTRY') {
      return orgDetails.list.data.section1.name.value;
    }

    if (type === 'REGION') {
      return orgDetails.list.data.cardDetails.region;
    }

    return '';
  };

  useEffect(() => {
    setIsLoading(hzaContext.uploadStatus.isLoading);
    setResponseText(hzaContext.uploadStatus.message);
    const status = hzaContext.uploadStatus.messageType.toLowerCase();

    switch (status) {
      case 'success':
        selectedFile && fileSource ? setOpenSuccess(true) : setOpenSuccess(false);
        break;
      case 'error':
        selectedFile && fileSource
          ? setOpenError(true)
          : snackBar.show(hzaContext.uploadStatus.message, 'error');
        break;
      case 'warning':
        setWfbTimestamp(hzaContext.uploadStatus.data);
        setErrorFileName(hzaContext.uploadStatus.data);
        selectedFile && fileSource ? setOpenWarning(true) : setErrorComments([]);
        break;
    }
  }, [hzaContext.uploadStatus]);

  useEffect(() => {
    if (hzaContext.downloadStatus.name === 'eei_errors') {
      setOpenWarning(hzaContext.downloadStatus.done);
    }
  }, [hzaContext.downloadStatus]);

  useEffect(() => {
    if (data && data.cardDetails) {
      setCardDetails({
        id: data.cardDetails.id,
        type: data.cardDetails.type,
        regionName: data.cardDetails.region,
      });
    }
    setYearList(() => {
      return years.map(year => ({ value: year, text: year }));
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(false);
      setSelectedFile(null);
      setSelectedKPI('');
      setIsLoading(false);
    } else {
      dispatch(
        userActivity({
          uniqueId: uuidv4(),
          service: USER_ACTIVITY_SERVICES.MANAGE_KPI,
          details: 'VIEW',
          uri: '',
        })
      );
    }
  }, [isOpen]);

  const handleKPIChange = event => {
    if (event.target.value === 'EEI' && !hzaIframeLoaded) {
      return setIsOpenC3(true);
    }

    setSelectedKPI(event.target.value);
    setSelectedOption(true);
    setDefaultYear(currentYear);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    dispatch(feedbackInsideModal(true));
  };

  const handleClose = () => {
    setIsOpen(false);
    hzaContext.clearStates();
    dispatch(feedbackInsideModal(false));
  };

  const handleSelectFile = e => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleDelete = async source => {
    if (selectedKPI == 'EEI') {
      const payload = {
        gbu: getNodeType('GBU', orgDetails.list.data.cardDetails.type),
        bl: getNodeType('BL', orgDetails.list.data.cardDetails.type),
        cc: getNodeType('CC', orgDetails.list.data.cardDetails.type),
        country: isCountry
          ? getNodeType('COUNTRY', orgDetails.list.data.cardDetails.type)
          : countryName,
        region: isRegion
          ? getNodeType('REGION', orgDetails.list.data.cardDetails.type)
          : regionName,
      };
      const data = {
        kpiDeleteRequest: payload,
      };
      const api = computeURL('eei', 'deleteEeiRow');
      const params = fetchOptions({ method: 'DELETE' });

      const jsonData = {
        ...data,
        year: source.year,
        rawEntriesFromFile: source.rawEntriesFromFile,
      };

      params.body = JSON.stringify(jsonData);
      await hzaContext.handleRequest(api, params, 'deleteEeiRow');
    }
    if (selectedKPI === 'WFB') {
      const jsonData = source;
      const api = computeURL('wfb', 'deleteWfbRow');
      const params = fetchOptions({ method: 'DELETE' });
      params.body = JSON.stringify(jsonData);
      await hzaContext.handleRequest(api, params, 'deleteWfbRow', true, true);
    }
  };

  const handleUpload = async source => {
    if (selectedKPI === 'EEI') {
      const orgName = isRegion
        ? orgDetails.list.data.cardDetails.region
        : orgDetails.list &&
          orgDetails.list.data.section1 &&
          orgDetails.list.data.section1.name.value;

      // replace "/" and "." from the filename because it crashes the server
      const regExp = /(\/)|(\.)/g;
      const formatOrgName = orgName.replace(regExp, '_');
      const date = moment(new Date()).format(DATE_TIME_FORMAT);
      const regexp = /(:)|(@)|(\/)|(\.)|(\\)/g;
      const fileName = `EEI_${formatOrgName}_2023_${date}`.replace(regexp, '_');
      let payload = {};

      if (isCountry) {
        payload = {
          orgId: cardDetails.id,
          country: getNodeType('COUNTRY', orgDetails.list.data.cardDetails.type),
        };
      } else if (isRegion) {
        payload = {
          country: getNodeType('REGION', orgDetails.list.data.cardDetails.type),
        };
      } else {
        const orgId = orgDetails.list.data.cardDetails.id;
        payload = {
          orgId: orgId,
          gbu: getNodeType('GBU', orgDetails.list.data.cardDetails.type),
          bl: getNodeType('BL', orgDetails.list.data.cardDetails.type),
          cc: getNodeType('CC', orgDetails.list.data.cardDetails.type),
        };
      }

      const data = {
        // kpiUploadRequest: payload,
      };

      const headers = { Accept: APPLICATION_JSON };
      let api = computeURL('eei', 'uploadEeiFromWebForms');
      const params = fetchOptionsUpload({ method: 'PUT', headers: headers });

      if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
        api = computeURL('eei', 'uploadEeiByCountry');
      }

      const mappedHeaders = {
        gbu: 'gbu',
        bl: 'bl',
        countries: 'country',
        cc: 'cc',
        period: 'period',
        'cfrd/sfrd': 'cfrd',
        'nb pjt': 'nbPjt',
        project_id: 'projectExternalId',
        project_name: 'projectName',
        'cbb eoy': 'cbbEoy',
        'actuals eoy': 'actualsEoy',
        'eac eoy': 'eacEoy',
        cbb: 'cbb',
        actuals: 'actuals',
        etc: 'etc',
        eac: 'eac',
        eacadj: 'eacAdj',
        'eei (%)': 'eei',
        'eacadj-eac': 'eacAdjEac',
        'target eei': 'target',
      };

      const jsonData = {
        ...data,
        // fileName: fileName,
      };

      if (selectedFile && source === 'file') {
        setFileSource(true);
        const reader = new FileReader();

        reader.readAsArrayBuffer(selectedFile);
        reader.onload = e => {
          const readerResult = e.target.result;
          if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
            const workbook = read(readerResult, {
              type: 'array',
              cellStyles: true,
              cellFormula: true,
            });
            const excelData = excelToJson(workbook, fileName, UPLOAD_EXCEL_TYPE.UPLOAD_EEI);
            const metadata = excelData.sheets[0].data.metadata;
            const orgId = orgDetails.list.data.cardDetails.id;

            jsonData.level = orgDetails.list.data.cardDetails.type;
            jsonData.year = metadata.rows[1].cells[1].value;
            jsonData.updateDate = metadata.rows[0].cells[1].value;
            jsonData.lastActualsQuarter = metadata.rows[2].cells[1].value;
            jsonData.sheets = [...excelData.sheets];
            jsonData.orgId = orgId;
          } else {
            const workbook = read(readerResult, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columns = {};
            let rows = getWorksheetRows(worksheet);

            Object.keys(rows[3]).forEach(key => {
              const columnHeader = rows[3][key].value.toLowerCase();
              columns[key] = mappedHeaders[columnHeader];
            });

            jsonData.fileName = fileName;
            jsonData.kpiUploadRequest = payload;
            jsonData.updateDate = Object.keys(rows[0])[1];
            jsonData.year = rows[1][Object.keys(rows[0])[1]].value;
            jsonData.lastActualsQuarter = rows[2][Object.keys(rows[0])[1]].value;
            jsonData.presentHeaders = Object.keys(rows[3]).map(key => {
              return rows[3][key].value;
            });

            rows = rows.splice(4);

            jsonData.rawEntriesFromFile = rows.map(row => {
              const transformedRow = {};

              Object.keys(row).forEach(key => {
                const value = row[key].value;
                const formula = row[key].formula;
                const column = columns[key];

                transformedRow[column] = formula ? formula : String(value);
              });

              return transformedRow;
            });
          }

          hzaContext.uploadFile(selectedFile, api, params, JSON.stringify(jsonData), 'uploadEEI');
        };
      } else {
        setFileSource(false);
        jsonData.updateDate = source.updateDate;
        jsonData.year = source.year;
        jsonData.lastActualsQuarter = source.lastActualsQuarter;
        jsonData.presentHeaders = source.presentHeaders;
        jsonData.rawEntriesFromFile = source.rawEntriesFromFile;
        jsonData.kpiUploadRequest = payload;
        const apiEEIByCountry = computeURL('eei', 'uploadEeiByCountry');
        if (!isFromByCountry) {
          const api = computeURL('eei', 'uploadEeiFromWebForms');
          hzaContext.uploadFile(
            selectedFile,
            api,
            params,
            JSON.stringify(jsonData),
            'uploadEEI',
            false,
            true
          );
        }
        if (isFromByCountry) {
          const payloadData = {
            orgId: source.orgId,
            updateDate: source.updateDate,
            year: source.year,
            lastActualsQuarter: source.lastActualsQuarter,
            sheets: source.sheets,
            level: orgDetails.list.data.cardDetails.type,
          };
          hzaContext.uploadFile(
            selectedFile,
            apiEEIByCountry,
            params,
            JSON.stringify(payloadData),
            'uploadEeiByCountry',
            false,
            true
          );
        }
      }
    }
    if (selectedKPI === 'WFB') {
      if (selectedFile && source === 'file') {
        setFileSource(true);
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = e => {
          try {
            uploadWFB(e);
          } catch (reason) {
            console.error(reason);
            snackBar.show('Invalid document', 'warning');
          }
        };
      } else {
        setFileSource(false);
        const jsonData = { sheets: [] };
        const headers = { Accept: APPLICATION_JSON };
        const api = computeURL('wfb', 'uploadWfbFromWebForms');
        const params = fetchOptionsUpload({ method: 'PUT', headers: headers });
        jsonData.sheets = source.sheets;
        hzaContext.uploadFile(
          selectedFile,
          api,
          params,
          JSON.stringify(jsonData),
          'uploadEEI',
          true,
          true
        );
      }
    }
  };

  const uploadWFB = e => {
    const excludedSheets = ['read me', 'param', 'em'];
    const data = e.target.result;
    const workbook = read(data, { type: 'array' });
    const sheetNames = workbook.SheetNames;
    const jsonData = { sheets: [] };
    const quartetsTypes = {
      'tar.': 'target',
      'for.': 'forecast',
      'act.': 'actual',
    };

    for (let i = 0; i < sheetNames.length; i++) {
      if (excludedSheets.includes(sheetNames[i].toLowerCase())) {
        continue;
      }

      jsonData.sheets.push({
        name: sheetNames[i],
        referenceYear: '',
        rows: [],
      });
    }

    for (let i = 0; i < jsonData.sheets.length; i++) {
      if (excludedSheets.includes(jsonData.sheets[i].name)) {
        continue;
      }

      const sheetData = jsonData.sheets[i];
      const worksheet = workbook.Sheets[sheetData.name];
      const json = utils.sheet_to_json(worksheet, { raw: true, defval: '' });
      let quarters = [
        ...new Set(
          Object.keys(json[3])
            .map(key => {
              return quartetsTypes[json[3][key].toLowerCase()];
            })
            .filter(value => {
              return value;
            })
        ),
      ];
      quarters = quarters.map(value => ({ [value]: { value: null, blocked: false } }));
      quarters = quarters.reduce((acc, currentObj) => ({ ...acc, ...currentObj }));

      jsonData.sheets[i].referenceYear = json[1]['__EMPTY_2'];

      for (let j = 0; j < json.length; j++) {
        const jsonRow = json[j];
        let entryTypes = [];

        if (!jsonRow[UPDATE_DATE] || jsonRow[UPDATE_DATE].toLowerCase() === 'gbu') {
          continue;
        }

        Object.keys(json[0]).forEach((colName, index) => {
          if (json[0][colName] && !['GBU', 'BL', 'CC', 'Countries'].includes(json[0][colName])) {
            entryTypes.push({
              name: json[0][colName],
              quarters: [
                { name: 'Q1', quarters },
                { name: 'Q2', quarters },
                { name: 'Q3', quarters },
                { name: 'Q4', quarters },
              ],
            });
          }
        });

        entryTypes = entryTypes.map((entryType, index) => {
          entryType.quarters = entryType.quarters.map((type, i) => {
            const row = Object.keys(jsonRow)
              .map(key => jsonRow[key])
              .splice(4);
            return mapQuarters(type.quarters, row, i, index);
          });
          return entryType;
        });

        const sheetRow = {
          gbu: jsonRow[UPDATE_DATE],
          countries: jsonRow['__EMPTY'],
          cc: jsonRow['__EMPTY_1'],
          bl: jsonRow[Object.keys(json[0])[1]],
          entryTypes,
        };

        jsonData.sheets[i].rows.push(sheetRow);
      }
    }

    const headers = { Accept: APPLICATION_JSON };
    const api = computeURL('wfb', 'uploadWfbFromWebForms');
    const params = fetchOptionsUpload({ method: 'PUT', headers: headers });

    hzaContext.uploadFile(selectedFile, api, params, JSON.stringify(jsonData), 'uploadEEI', true);
  };

  const mapQuarters = (quarters, row, quarter, index) => {
    const mappedQuarters = { ...quarters };
    let hasAll = false;
    let key1 = '';
    let key2 = '';
    let key3 = '';

    const checkValue = value => {
      if (value === '') {
        return { value: null, blocked: false };
      }

      return { value: String(value), blocked: false };
    };

    if (Object.keys(quarters).length === 3) {
      hasAll = true;
      const multi = index * 11;
      if (quarter === 0) {
        key1 = index + quarter + multi;
        key2 = index + quarter + multi + 1;
        key3 = index + quarter + multi + 2;
      }
      if (quarter === 1) {
        key1 = index + quarter + multi + 2;
        key2 = index + quarter + multi + 3;
        key3 = index + quarter + multi + 4;
      }
      if (quarter === 2) {
        key1 = index + quarter + multi + 4;
        key2 = index + quarter + multi + 5;
        key3 = index + quarter + multi + 6;
      }
      if (quarter === 3) {
        key1 = index + quarter + multi + 6;
        key2 = index + quarter + multi + 7;
        key3 = index + quarter + multi + 8;
      }
    } else {
      const multi = index * 7;
      if (quarter === 0) {
        key1 = index + quarter + multi;
        key2 = index + quarter + multi + 1;
      }
      if (quarter === 1) {
        key1 = index + quarter + multi + 1;
        key2 = index + quarter + multi + 2;
      }
      if (quarter === 2) {
        key1 = index + quarter + multi + 2;
        key2 = index + quarter + multi + 3;
      }
      if (quarter === 3) {
        key1 = index + quarter + multi + 3;
        key2 = index + quarter + multi + 4;
      }
    }

    if (hasAll) {
      mappedQuarters.target = checkValue(row[key1]);
      mappedQuarters.forecast = checkValue(row[key2]);
      mappedQuarters.actual = checkValue(row[key3]);
    } else {
      mappedQuarters.forecast = checkValue(row[key1]);
      mappedQuarters.actual = checkValue(row[key2]);
    }
    return mappedQuarters;
  };

  const handleOpenSuccess = () => {
    setIsUploadDisabled(!isUploadDisabled);
    setOpenSuccess(!openSuccess);
    setIsOpen(false);
  };

  const handleOpenError = async () => {
    if (selectedKPI === 'EEI') {
      if (!isFromByCountry) {
        const api = computeURL('eei', 'getEEILogs', errorFileName);
        const params = fetchOptions({
          method: 'get',
          credentials: 'include',
          headers: {
            'Content-Type': APPLICATION_JSON,
            Accept: 'application/octet-stream',
            'Accept-Encoding': 'gzip, deflate, br',
          },
        });
        hzaContext.downloadFile(errorFileName, api, params, 'eei_errors');
      }
      if (isFromByCountry) {
        await jsonToExcel(`${orgDetails.list.data.section1.name.value} - errors`, errorFileName);
      }
    }

    if (selectedKPI === 'WFB') {
      const orgName = isRegion
        ? orgDetails.list.data.cardDetails.region
        : orgDetails.list &&
          orgDetails.list.data.section1 &&
          orgDetails.list.data.section1.name.value;
      const date = moment(new Date()).format(DATE_TIME_FORMAT);
      const api = computeURL('wfb', 'downloadWfbWithErrors');
      const fileName = `WFB_${orgName}_${date}_errors`;
      const params = fetchOptions({
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': APPLICATION_JSON,
          Accept: 'application/octet-stream',
          'Accept-Encoding': 'gzip, deflate, br',
        },
        body: JSON.stringify({ requestTimestamp: errorFileName }),
      });

      const skipHza = selectedKPI.toLowerCase() === 'wfb';
      hzaContext.downloadFile(fileName, api, params, 'eei_download', skipHza);
    }
  };

  const getKPIErrors = async () => {
    if (selectedKPI === 'EEI' && !isFromByCountry) {
      const api = computeURL('eei', 'getEEILogsWebForm', hzaContext.uploadStatus.data);
      const params = fetchOptionsUpload({ method: 'GET' });
      hzaContext.handleRequest(api, params, 'getEEILogsWebForm');
    }
    if (selectedKPI === 'WFB') {
      const api = computeURL('wfb', 'downloadWfbWithErrorsForm');
      const params = fetchOptions({ method: 'PUT' });
      params.body = JSON.stringify({ requestTimestamp: errorFileName });
      hzaContext.handleRequest(api, params, 'downloadWfbWithErrorsForm', true, true);
    }
  };

  return (
    <>
      <Grid>
        <EmwButton
          variant="ghost"
          size="small"
          title={`${hoverTitle}`}
          id="btn-KPI"
          onClick={handleClick}
        >
          <TdsIcon icon="compare_arrows" variant="outlined" size="large" />
        </EmwButton>

        {isOpen && (
          <StyledKPIDialog open={isOpen} className="px-m">
            <TourElement id="tour-KPI">
              <DialogTitle className="px-m pt-m pb-xxs flex justify-between">
                <Grid
                  container
                  direction="row"
                  className="mb-s justify-between"
                  id="export-kpi-header"
                >
                  <Grid item className="flex">
                    <StyledHeaderTitle className="flex flex-col">
                      <TdsTypography component="body-1" classes="text-primary-500">
                        {title}
                      </TdsTypography>
                      <TdsTypography component="body-1" fontSize={9}>
                        {isGroup ? subtitleGroup : subtitle}
                      </TdsTypography>
                    </StyledHeaderTitle>
                  </Grid>
                  <Grid item id="select-kpi-dropdown" className="flex mt-xxs">
                    <EmwDropDownList
                      options={kpiOptions}
                      onDropDownListChanged={handleKPIChange}
                      value={selectedKPI}
                      placement="bottom"
                      inputId="KPI"
                      placeHolder="Select KPI"
                    />
                  </Grid>
                </Grid>
              </DialogTitle>
            </TourElement>
            <TourElement id="tour-download">
              <DialogContent className="px-m pb-xs pt-none">
                <Grid item id="export-kpi-content">
                  <KPIActions
                    cardDetails={cardDetails}
                    handleSelectFile={handleSelectFile}
                    selectedOption={selectedOption}
                    selectedFile={selectedFile}
                    yearList={yearList}
                    defaultYear={defaultYear}
                    setDefaultYear={setDefaultYear}
                    selectedKPI={selectedKPI}
                    isGroup={isGroup}
                    isRegion={isRegion}
                    isLoading={isLoading}
                    handleUpload={handleUpload}
                    countryName={countryName}
                    errorComments={errorComments}
                    errorFileName={errorFileName}
                    getKPIErrors={getKPIErrors}
                    isFromByCountry={isFromByCountry}
                    handleDelete={handleDelete}
                    regionName={regionName}
                  />
                </Grid>
              </DialogContent>
            </TourElement>
            <DialogActions className="flex justify-between px-m pb-s" id="export-kpi-actions">
              <EmwButton
                id="btn-cancel-KPI"
                variant="filled"
                color="primary"
                size="small"
                onClick={handleClose}
              >
                <EmwTypography classes="font-bold">Close</EmwTypography>
              </EmwButton>
            </DialogActions>
            {selectedKPI === 'EEI' && (
              <Grid item id="export-kpi-warning-text" className="flex justify-center">
                <EmwTypography classes="text-orange-500" fontSize={8}>
                  {THALES_GROUP_C3}
                </EmwTypography>
              </Grid>
            )}
            {selectedKPI === 'WFB' && (
              <Grid item id="export-kpi-warning-text" className="flex justify-center">
                <EmwTypography classes="text-green-500" fontSize={8}>
                  {THALES_GROUP_C2}
                </EmwTypography>
              </Grid>
            )}
            <FeedbackButton title={title} />
          </StyledKPIDialog>
        )}

        {openSuccess && (
          <KPISuccessModal
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            handleOpenSuccess={handleOpenSuccess}
            text={responseText}
            isOpen={isOpen}
            id="kpi-success-modal"
          />
        )}
        {openError && (
          <KPIErrorModal
            openError={openError}
            setOpenError={setOpenError}
            // handleOpenError={handleOpenError}
            text={responseText}
            title={'Not imported'}
            id="kpi-error-modal"
          />
        )}
        {openWarning && (
          <KPIErrorModal
            openError={openWarning}
            setOpenError={setOpenWarning}
            handleOpenError={handleOpenError}
            text={responseText}
            file={errorFileName}
            title={'Imported with errors'}
            id="kpi-error-modal"
            buttonIcon="arrow_circle_down"
          />
        )}
      </Grid>
      {isOpenC3 && (
        <EmwDialog
          isOpen={isOpenC3}
          setIsOpen={setIsOpenC3}
          callback={() => setIsOpenC3(false)}
          actionButton="Ok"
          title="HZA is not available"
          text="You are not allowed to use this service due to C3 restriction"
          icon="warning_amber"
          classes="reset-icon"
        />
      )}
    </>
  );
}
