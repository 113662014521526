import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, DialogContent, DialogTitle, DialogActions, Divider } from '@mui/material';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';
import EmwTypography from '../EmwTypography/EmwTypography';
import { StyledHotTable, StyledEmwWebTable } from './styled';
import EmwFooterEnvInfo from '../EmwFooterEnvInfo/EmwFooterEnvInfo';
import EmwRichTextFormatting from '../EmwHotTable/EmwRichTextFormatting';
import EmwProgressCircle from '../EmwProgressCircle/EmwProgressCircle';
import EmwButton from '../EmwButton/EmwButton';
import moment from 'moment';
import { DATE_TIME_FORMAT, DATE_FORMAT } from '../../features/organization/constants';
import { jsonToExcel } from '../../utils/xcelUtils';
import { TdsIcon } from '@tds/react';
import useAppSnackbar from '../../hooks/useAppSnankbar';

// register Handsontable's modules
registerAllModules();
/**
 * @todo Make this function generic and to not be dependent on EmwDialog with name EmwHotTable(id, data, settings?, afterChange, afterFilter)
 * @todo Implement default settings
 * @deprecated Needs to be refactored see todos
 * @returns
 */
export default function EmwWebExcel({
  id,
  isOpen,
  setIsOpen,
  fixedColumns,
  saveApi,
  getApi,
  colWidth,
  pageTitle,
  isDrillToDetails,
  payload,
  data,
  canAdd,
  saveNewRowApi,
  mandatoryCols,
}) {
  const formRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formHeader, setFormHeader] = useState([]);
  const [rowIdMap, setRowIdMap] = useState({});
  const form = formRef.current ? formRef.current.hotInstance : '';
  const [dropdownItems, setDropdownItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const date = moment(new Date()).format(DATE_TIME_FORMAT);
  const [isNewRow, setIsNewRow] = useState(false);
  const [newRow, setNewRow] = useState([]);
  const [editedRowIndex, setEditedRowIndex] = useState('');
  const [newRowIndex, setNewRowIndex] = useState('');
  const [canAddNewRow, setCanAddNewRow] = useState(true);
  const rolesOptions = ['Admin', 'Super User', 'Contributor'];
  const [hasEmptyRow, setHasEmptyRow] = useState(false);
  const snackbar = useAppSnackbar();
  const [filters, setFilters] = useState([]);

  const fetchData = async payload => {
    const response = await getApi(payload);
    if (response && response.data) {
      setFormHeader(response.data.header);
      setFormData(response.data.rows);
    }
  };

  useEffect(() => {
    if (tableData && form) {
      tableData.forEach(() => {
        setCellType(form);
      });

      form.render();
    }
    if (isDrillToDetails && form) {
      const test = form.getData();
      form.updateSettings({
        data: tableData,
      });
    }
  }, [tableData]);

  useEffect(() => {
    if (isDrillToDetails && !formData.length) {
      //force empty row to display headers
      setTableData([[null, null, null, null, null, null, null, null, null]]);
      setHasEmptyRow(true);
    } else {
      setTableData(updatedFormData);
      setHasEmptyRow(false);
      setDropdownItems(getAllDropdownValues);
      const idMap = {};
      updatedFormData.forEach((row, index) => {
        idMap[index] = row.id;
      });
      setRowIdMap(idMap);
    }
  }, [formData]);

  useEffect(() => {
    if (isDrillToDetails && data) {
      setFormHeader(data.header);
      setFormData(data.rows);
    }
  }, [data]);

  useEffect(() => {
    if (!isDrillToDetails) {
      fetchData();
    }
  }, [isOpen]);

  const filteredHeaders = useMemo(() => {
    return formHeader.length > 0 && formHeader[formHeader.length - 1].map(item => item);
  }, [formHeader]);

  const updatedFormData = formData.map(row => {
    row.cels.forEach(cell => {
      if (cell.valueType === 'date') {
        cell.value = cell.value ? moment(cell.value).format(DATE_FORMAT) : '';
      }
    });
    return {
      id: row.id,
      ...row.cels.reduce((acc, cel) => {
        acc[cel.property] = cel.value;
        return acc;
      }, {}),
    };
  });

  const getAllDropdownValues = () => {
    const values = [];
    formData.forEach(row => {
      row.cels.forEach(cel => {
        if (cel.values.length > 0) {
          cel.values.forEach(item => {
            values.push({ id: row.id, property: cel.property, item: item });
          });
        }
      });
    });
    return values;
  };

  const setCellType = form => {
    formData.forEach((row, rowIndex) => {
      row.cels.forEach((cel, celIndex) => {
        if (!cel.editable) {
          form.setCellMeta(rowIndex, celIndex + 1, 'readOnly', true);
        }
        if (cel.values.length > 0) {
          const dropdownValues = cel.values.map(item => {
            return item.value;
          });
          if (cel.editType === 'dropdownlist') {
            form.setCellMeta(rowIndex, celIndex + 1, 'source', dropdownValues);
            form.setCellMeta(rowIndex, celIndex + 1, 'type', 'dropdown');
          }
        }
        if (cel.editType === 'checkbox') {
          form.setCellMeta(rowIndex, celIndex + 1, 'type', 'checkbox');
        }
        if (cel.editType === 'percent') {
          form.setCellMetaObject(rowIndex, celIndex + 1, {
            type: 'numeric',
            format: '0.00%',
          });
        }
        if (cel.editType === 'date') {
          form.setCellMetaObject(rowIndex, celIndex + 1, {
            type: 'date',
            dateFormat: 'DD/MM/YYYY',
            correctFormat: true,
            datePickerConfig: {
              firstDay: 1,
            },
          });
        }
      });
    });
  };

  const getNestedHeaders = () => {
    const nestedHeaders = [];
    if (formHeader && formHeader.length > 0) {
      for (let i = 0; i < formHeader.length; i++) {
        const nh = [];
        nh.push({
          label: '',
          colspan: 1,
        });
        for (let j = 0; j < formHeader[i].length; j++) {
          if (formHeader[i][j].visible) {
            nh.push({
              label: formHeader[i][j].value,
              colspan: formHeader[i][j].colspan,
            });
          }
        }
        nestedHeaders.push(nh);
      }
    }
    return nestedHeaders;
  };

  const setMandatoryColsStyle = row => {
    mandatoryCols.forEach(col => {
      form.setCellMeta(row, col, 'className', 'red-border');
    });
  };

  const handleValidate = (value, callback) => {
    if (form) {
      form.validateCells();
    }
  };

  const emptyCellsValidator = (value, callback) => {
    setTimeout(() => {
      if (value) {
        callback(true);
      } else {
        callback(false);
      }
    }, 50);
  };
  Handsontable.validators.registerValidator('empty-cell', emptyCellsValidator);

  const updateRowMap = index => {
    setRowIdMap(prevRowIdMap => {
      const updatedRowIdMap = {};

      Object.entries(prevRowIdMap).forEach(([key, value]) => {
        const numKey = parseInt(key);
        if (numKey >= index) {
          updatedRowIdMap[numKey + 1] = value;
        } else {
          updatedRowIdMap[numKey] = value;
        }
      });

      updatedRowIdMap[index] = null;
      return updatedRowIdMap;
    });
  };

  const formSettings = {
    id: id,
    height: '98%',
    width: 'auto',
    ref: formRef,
    filters: true,
    filter: true,
    stretchH: 'all',
    rowHeaders: true,
    colHeaders: true,
    autoColumnSize: true,
    colWidths: colWidth,
    undo: true,
    allowInsertColumn: true,
    allowInsertRow: canAddNewRow,
    contextMenu: canAdd ? { items: { row_above: {}, row_below: {} } } : false,
    allowRemoveColumn: false,
    allowRemoveRow: false,
    redo: true,
    columnHeaderHeight: 35,
    manualColumnResize: true,
    rowHeaderWidth: 40,
    className: 'className',
    comments: true,
    trimRows: hasEmptyRow ? [0] : [11111],
    licenseKey: 'non-commercial-and-evaluation',
    selectionMode: 'multiple',
    outsideClickDeselects: false,
    invalidCellClassName: 'red-border',
    beforeChange: (changes, source) => {
      if (canAdd && changes) {
        const indexes = [];
        changes.forEach(change => {
          indexes.push(change[0]);
        });
        setEditedRowIndex(indexes);
      }
    },
    afterChange: (changes, source) => {
      if (changes) {
        if (canAdd && editedRowIndex && editedRowIndex.includes(newRowIndex)) {
          handleAddNewRow(changes);
        } else {
          // setIsNewRow(false);
          handleSave(changes);
        }
        if (canAdd) {
          handleValidate();
        }
      }
    },
    afterUpdateData: (sourceData, initialLoad, source) => {
      // TO-DO: see why this returns error
      // const formInstance = formRef && formRef.current ? formRef.current.hotInstance : '';
      // if (isDrillToDetails && formInstance && filters.length) {
      //   const tableFilters = formInstance.getPlugin('filters');
      //   setTimeout(() => {
      //     tableFilters.clearConditions();
      //     tableFilters.addCondition(filters);
      //     tableFilters.filter();
      //   }, 500);
      // }
    },
    beforeCreateRow: (index, amount, source) => {
      setTimeout(() => {
        setIsNewRow(true);
      }, 50);
      setNewRowIndex(index);
      setCanAddNewRow(false);
    },
    afterCreateRow: (index, amount, source) => {
      if (!isDrillToDetails) {
        setCanAddNewRow(false);
        const emptyRow = form.getDataAtRow(index);
        const isRoleCol = form.colToProp(emptyRow.length - 3) === 'Role';
        const currentDate = moment().format('DD/MM/YYYY');
        //@TODO: handle start date and end date in a generic way
        const startDateCell = emptyRow.length - 2;
        const endDateCell = emptyRow.length - 1;
        const roleCell = emptyRow.length - 3;
        updateRowMap(index);
        setMandatoryColsStyle(index);
        form.updateSettings({
          cells: (row, col) => {
            const cellProperties = {};
            if (mandatoryCols.includes(col)) {
              cellProperties.allowEmpty = false;
              cellProperties.validator = 'empty-cell';
            }
            if (col === startDateCell) {
              cellProperties.readOnly = true;
            }
            if (col === endDateCell) {
              cellProperties.type = 'date';
            }
            if (isRoleCol && col === roleCell) {
              cellProperties.type = 'dropdown';
              cellProperties.source = rolesOptions;
            }
            if (row !== index && isRoleCol && col !== endDateCell) {
              cellProperties.readOnly = true;
            }
            return cellProperties;
          },
        });
        handleValidate();
        form.setDataAtCell(index, startDateCell, currentDate);
        setNewRow([]);
      }
    },
    afterFilter: conditionsStack => {
      if (conditionsStack && conditionsStack[0] && conditionsStack[0].conditions) {
        const currentFilters = {
          column: conditionsStack[0].column,
          filterBy: conditionsStack[0].conditions[0].name,
          value: conditionsStack[0].conditions[0].args[0],
        };
        setFilters([currentFilters.column, currentFilters.filterBy, currentFilters.value]);
      }
      const formInstance = formRef.current.hotInstance;
      const filteredRows = formInstance.getData();
      setFilteredData(filteredRows);
      const idMap = {};
      filteredRows.forEach((row, index) => {
        idMap[index] = row[0];
      });
      setRowIdMap(idMap);
    },
  };

  const handleAddNewRow = changes => {
    changes.forEach(change => {
      const editData = {
        cellProperty: change[1],
        value: change[3],
      };

      setNewRow(prevNewRow => {
        const updatedNewRow = [...prevNewRow, editData];
        if (updatedNewRow.length === mandatoryCols.length) {
          handleNewRowSave(updatedNewRow);
        }
        return updatedNewRow;
      });
      setIsNewRow(false);
    });
  };

  const payloadSave = change => {
    if (!change) {
      return;
    }
    if (change[1] === 'End_Date') {
      const parts = change[3].split('/');
      const reversedDate = parts.length > 1 ? new Date(`${parts[2]}-${parts[1]}-${parts[0]}`) : '';
      change[3] = reversedDate ? reversedDate.toISOString().slice(0, 19) : '';
    }
    const editData = {
      rowId: rowIdMap[change[0]],
      cellProperty: change[1],
      value: change[3],
    };
    const filteredData = dropdownItems.filter(item => {
      const c1 = editData.rowId === item.id;
      const c2 = editData.cellProperty == item.property;
      const c3 = editData.value == item.item.value;
      return c1 && c2 && c3;
    });
    if (filteredData.length > 0) {
      editData.value = filteredData[0].item.id;
    }
    return editData;
  };

  const handleSave = async changes => {
    if (changes.length && changes[0][2] != changes[0][3]) {
      const payload = changes.map(row => {
        return payloadSave(row);
      });
      try {
        await saveApi(payload);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleNewRowSave = async changes => {
    if (changes.length) {
      setCanAddNewRow(true);
      const payload = changes.map(row => row);
      const response = await saveNewRowApi(payload);
      if (!response.data) {
        snackbar.show(response.message, 'error');
      } else {
        const newRowId = response.data;
        const idToReplace = Object.entries(rowIdMap).find(([key, value]) => value === null)?.[0];
        if (idToReplace !== undefined) {
          setRowIdMap(prevRowIdMap => ({
            ...prevRowIdMap,
            [idToReplace]: newRowId,
          }));
        }
      }
    }
    setEditedRowIndex('');
    setNewRowIndex('');
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleAddNewLine = () => {
    if (form && canAddNewRow) {
      form.alter('insert_row_above', 0, 1);
    }
  };

  const handleUndo = () => {
    if (form) {
      form.undo();
    }
  };

  const handleRedo = () => {
    if (form) {
      form.redo();
    }
  };

  const removeColumnMenuButton = (col, TH) => {
    if (col > -1 && filteredHeaders[col] && !filteredHeaders[col].filtrable) {
      const button = TH.querySelector('.changeType');
      if (!button) {
        return;
      }
      button.parentElement.removeChild(button);
    }
  };

  const transformFilteredRows = filteredData => {
    const filteredRowIds = new Set(filteredData.map(filteredRow => filteredRow[0]));
    const filteredRows = data.rows.filter(row => {
      if (filteredRowIds.has(row.id)) {
        return row;
      }
    });

    return { ...data, rows: filteredRows };
  };

  const handleDownload = async () => {
    const dataToDownload = filteredData.length ? transformFilteredRows(filteredData) : data;
    if (dataToDownload) {
      try {
        setIsLoading(true);
        await jsonToExcel(`Export_Usage_Monitoring_Data_${date}`, dataToDownload, true);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
  };

  return !isDrillToDetails ? (
    <StyledEmwWebTable
      open={isOpen}
      fullScreen
      id="form-dialog-window"
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <DialogTitle className="flex">
        <Grid container id="form-dialog-header" className="flex items-center justify-between">
          <Grid item className="flex items-center" id="form-dialog-title">
            <EmwTypography fontSize={6} classes="text-primary-500 font-bold">
              {pageTitle}
            </EmwTypography>
          </Grid>
          <Grid item className="flex" id="form-dialog-header-actions">
            <Grid item className="flex" id="form-dialog-text-formatting">
              <EmwRichTextFormatting
                handleUndo={handleUndo}
                handleRedo={handleRedo}
                handleAddNewLine={handleAddNewLine}
                canAdd={canAdd}
              />
            </Grid>
            <Grid item className="flex" id="form-dialog-action-buttons">
              <EmwButton
                color="primary"
                variant="outline"
                classes="mr-s"
                size="small"
                onClick={handleCancel}
                id="btnClose"
              >
                Close
              </EmwButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id="form-dialog-content">
        <div className="loading-wrapper">
          {tableData.length === 0 && <EmwProgressCircle size="small" />}
        </div>
        <HotTable
          root="form"
          settings={formSettings}
          className="handsontable"
          id={id}
          data={tableData}
          ref={formRef}
          columnSorting={true}
          fixedColumnsStart={fixedColumns}
          manualColumnFreeze={fixedColumns > 0}
          hiddenColumns={{
            columns: [0],
          }}
          dropdownMenu={{
            items: {
              filter_by_value: {
                hidden() {
                  return false;
                },
              },
              filter_action_bar: {
                hidden() {
                  return false;
                },
              },
            },
          }}
          nestedHeaders={getNestedHeaders()}
          afterGetColHeader={removeColumnMenuButton}
        />
      </DialogContent>
      <Divider flexItem />
      <DialogActions className="justify-between h-xxl" id="form-dialog-actions">
        <EmwFooterEnvInfo />
      </DialogActions>
    </StyledEmwWebTable>
  ) : (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div className="flex items-center justify-between mt-l">
        <EmwTypography classes={'mb-s mt-s'}>Number of conections per user</EmwTypography>
        {data.rows.length > 0 && (
          <EmwButton
            variant="filled"
            title="Download data"
            size="small"
            onClick={handleDownload}
            id="download-drill-to-details-btn"
            disabled={isLoading}
          >
            <span className="flex">
              {isLoading ? (
                <EmwProgressCircle />
              ) : (
                <TdsIcon icon="download" size="small" variant="outlined"></TdsIcon>
              )}
              <EmwTypography classes="font-bold">Export</EmwTypography>
            </span>
          </EmwButton>
        )}
      </div>
      <StyledHotTable
        root="form"
        settings={formSettings}
        className="handsontable"
        id={id}
        data={tableData}
        ref={formRef}
        columnSorting={true}
        fixedColumnsStart={fixedColumns}
        manualColumnFreeze={fixedColumns > 0}
        hiddenColumns={{
          columns: [0],
        }}
        dropdownMenu={{
          items: {
            filter_by_value: {
              hidden() {
                return false;
              },
            },
            filter_action_bar: {
              hidden() {
                return false;
              },
            },
          },
        }}
        nestedHeaders={getNestedHeaders()}
        afterGetColHeader={removeColumnMenuButton}
      />
    </div>
  );
}
