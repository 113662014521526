export const MODULE_NAME = 'ADMINISTRATION';
export const ADMINISTRATION_PAGES = [
  {
    key: 'admin-tools',
    path: '/administration',
    name: 'Admin Tools',
    icon: 'settings',
    variant: 'filled',
    disabled: false,
    permissions: 'showAdminPage',
    admin: true,
    public: false,
  },
  {
    key: 'usage-monitoring',
    path: '/administration/usage-monitoring',
    name: 'Usage Monitoring',
    icon: 'bar_chart',
    variant: 'filled',
    disabled: false,
    permissions: 'showAdminPage',
    admin: true,
    public: true,
  },
  {
    key: 'engineering-compliance',
    path: '/administration/engineering-compliance',
    name: 'Engineering Compliance',
    icon: 'engineering',
    variant: 'filled',
    disabled: false,
    permissions: 'showAdminPage',
    admin: true,
    public: false,
  },
];

export const DATA_COLLECTION = [
  {
    key: 'data-collection',
    path: '/data-collection-evolution',
    name: 'Data Collection Evolution',
    icon: 'assessment',
    variant: 'outlined',
    disabled: false,
    permissions: 'showAdminPage',
    admin: false,
    public: false,
  },
];

export const ROLES = [
  {
    value: 'all',
    label: 'Select All',
  },
  {
    value: 'EM',
    label: 'EM',
  },
  {
    value: 'HoEO',
    label: 'HoEO',
  },
  {
    value: 'KPIS',
    label: 'KPIS',
  },
  {
    value: 'HoD',
    label: 'HoD',
  },
  {
    value: 'HoED',
    label: 'HoED',
  },
  {
    value: 'EDM',
    label: 'EDM',
  },
  {
    value: 'WPM',
    label: 'WPM',
  },
  {
    value: 'DA',
    label: 'DA',
  },
  {
    value: 'PDA',
    label: 'PDA',
  },
  {
    value: 'DR',
    label: 'DR',
  },
  {
    value: 'CybSR',
    label: 'CybSR',
  },
  {
    value: 'EcoDR',
    label: 'EcoDR',
  },
  {
    value: 'UXDR',
    label: 'UXDR',
  },
  {
    value: 'DPR',
    label: 'DPR',
  },
  {
    value: 'MoBFR',
    label: 'MoBFR',
  },
  {
    value: 'EQL',
    label: 'EQL',
  },
  {
    value: 'CER',
    label: 'CER',
  },
  {
    value: 'G',
    label: 'G',
  },
  {
    value: 'PG',
    label: 'PG',
  },
  {
    value: 'EXCOM',
    label: 'EXCOM',
  },
  {
    value: 'SU',
    label: 'SU',
  },
  {
    value: 'QESCM',
    label: 'QESCM',
  },
  {
    value: 'Admin',
    label: 'Admin',
  },
  {
    value: 'Contributor',
    label: 'Contributor',
  },
  {
    value: 'HRM',
    label: 'HRM',
  },
];

export const SERVICES = [
  {
    value: 'all',
    label: 'Select All',
  },
  {
    value: 'Allocate CC',
    label: 'Allocate CC',
  },
  {
    value: 'Engineering Organization View',
    label: 'Engineering Organization View',
  },
  {
    value: 'Engineering Cockpit',
    label: 'Engineering Cockpit',
  },
  {
    value: 'Engineering Organization Edit',
    label: 'Engineering Organization Edit',
  },
  {
    value: 'Engineering Organization Export',
    label: 'Engineering Organization Export',
  },
  {
    value: 'Guest/PowerGuest Edit',
    label: 'Guest/PowerGuest Edit',
  },
  {
    value: 'Guest/PowerGuest View',
    label: 'Guest/PowerGuest View',
  },
  {
    value: 'Manage KPI data',
    label: 'Manage KPI data',
  },
  {
    value: 'Mood & Confidence',
    label: 'Mood & Confidence',
  },
  {
    value: 'Mood & Confidence Drill Through',
    label: 'Mood & Confidence Drill Through',
  },
  {
    value: 'Mood & Confidence Drill to details',
    label: 'Mood & Confidence Drill to details',
  },
  {
    value: 'Mood & Confidence with Project Names',
    label: 'Mood & Confidence with Project Names',
  },
  {
    value: 'Operating Model',
    label: 'Operating Model',
  },
  {
    value: 'Search User/Organization',
    label: 'Search User/Organization',
  },
  {
    value: 'Talents & Culture',
    label: 'Talents & Culture',
  },
];
