import React, { useMemo } from 'react';
import { StyledEmList, StyledEmListItem, StyledReferentListItem } from '../../Ems/styled';
import EmwTypography from '../../../../../../../components/EmwTypography/EmwTypography';
import { userSelector } from '../../../../../../header/redux/selectors';
import { orgDetailsSelector } from '../../../../../redux/selectors';
import cls from 'classnames';
import EmwTag from '../../../../../../../components/EmwTag/EmwTag';
import { NO_RESOURCE_ALLOCATED } from '../../../../../constants';
import EmwListItemName from '../../Ems/EmsListItemName';

export default function EngineersList({ engineers, engCategory }) {
  const itemClassName = cls('em-list-item mt-s', {
    section1: true /*section === 'section1'*/,
  });

  const items = useMemo(() => {
    return [...engineers].map((engineer, index) => {
      if (engineer.name.value === NO_RESOURCE_ALLOCATED) {
        return <EmwListItemName em={engineer} />;
      }
      return (
        <EmwTypography fontSize={8}>
          <EmwTag
            variant="CUSTOMIZED"
            classes="mr-xs mb-xs"
            size="small"
            dismissible={false}
            text={engineer.name.value}
            referent={engineer}
            employeeId={engineer.id}
            // parentId={parentId}
            // orgId={organization.list.data.cardDetails.id}
            // reloadParent={reloadParent}
            // reload={reload}
            isEngineer={true}
          />
        </EmwTypography>
      );
    });
  }, [engineers]);

  return (
    <StyledEmListItem container className={itemClassName}>
      <div className={'flex flex-1 items-center rounded-xl bg-bluegrey-25 pl-xs'}>
        <div className="flex">
          <div className="flex items-center role-user">
            <EmwTypography
              fontSize="9"
              fontWeight="bold"
              classes="text-bluegrey-800"
              title={engineers[0].labelInfo}
            >
              {engineers[0].label}:
            </EmwTypography>
          </div>
          <div className="flex flex-wrap chip">{items}</div>
        </div>
      </div>
    </StyledEmListItem>
  );
}
